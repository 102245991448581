import React from 'react';
import { Auth } from "aws-amplify";
import awsExports from './aws-exports';
import venmo from './venmo.png';
import {
  Col,
  Row,
  Table,
  Tabs,
  Button,
  FloatButton,
  Modal,
  Form,
  Input,
  InputNumber,
  TimePicker,
  DatePicker,
  Select,
  Card,
} from 'antd';
import {
  FileTextOutlined,
  DeleteTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';

const third_party_options = [
  {
    value: 'FOREUP',
    label: 'FOREUP',
  },
];

class UserLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: null,
      tp_accounts: null,
      active_requests: null,
      inactive_requests: null,
      showNewCourseForm: false,
      newCourseFormThirdParty: null,
      showNewAccountForm: false,
      showNewRequestForm: false,
      newRequestFormCourse: null,
      newRequestFormStrategy: null,
      showDonateModal: false,
    }
  }

  componentDidMount() {
    this.fetchActiveRequests();
    this.fetchInactiveRequests();
    this.fetchCourses();
    this.fetchTpAccounts();
  }

  fetchActiveRequests() {
    this.fetchAuthenticated('/active_requests')
      .then(response => response.json())
      .then(response => this.handleRequests(response, true))
      .catch((error) => alert("Failed to fetch active requests: " + error));
  }

  fetchInactiveRequests() {
    this.fetchAuthenticated('/inactive_requests')
      .then(response => response.json())
      .then(response => this.handleRequests(response, false))
      .catch((error) => alert("Failed to fetch inactive requests: " + error));
  }

  fetchCourses() {
    this.fetchAuthenticated('/courses')
      .then(response => response.json())
      .then(response => this.handleCourses(response))
      .catch((error) => alert("Failed to fetch courses: " + error));
  }

  fetchTpAccounts() {
    this.fetchAuthenticated('/tp_accounts')
      .then(response => response.json())
      .then(response => this.handleTpAccounts(response))
      .catch((error) => alert("Failed to fetch tp_accounts: " + error));
  }

  fetchAuthenticated(path, method='GET', data=undefined) {
    return Auth.currentSession()
      .then((data) => {
        let accessToken = data.getAccessToken()
        let jwt = accessToken.getJwtToken()
        return {'Authorization': 'Bearer ' + jwt};
      })
      .then((headers) => fetch(process.env.REACT_APP_TEE_TIMES_API_SERVER + path,
                               { method: method,
                                 body: data !== undefined ? JSON.stringify(data) : undefined,
                                 headers: headers }))
      .then((response) => {
        if (response.status !== 200) {
          alert("Request failed with code " + response.status + ": " + response.statusText);
          return null;
        }
        return response;
      });
  }

  handleRequests(requests, active) {
    var reqs = [];
    for (let req of requests) {
      const formatted = {
        id: req.id,
        key: req.id,
        course: req.course,
        date: new Date(req.date_sec * 1000),
        status: req.status,
        error_msg: req.error_msg,
      };

      if (req.exact_booking_strategy !== undefined) {
        formatted['exact_time'] = new Date(req.exact_booking_strategy.exact_time_sec * 1000);
      } else {
        formatted['earliest_time'] = new Date(req.range_booking_strategy.earliest_time_sec * 1000);
        formatted['latest_time'] = new Date(req.range_booking_strategy.latest_time_sec * 1000);
        formatted['preferred_time'] = new Date(req.range_booking_strategy.preferred_time_sec * 1000);
      }

      reqs.push(formatted);
    }

    if (active)
      this.setState({active_requests: reqs});
    else
      this.setState({inactive_requests: reqs});
  }

  handleCourses(courses) {
    for (let course of courses)
      course['key'] = course['id']
    this.setState({courses: courses});
  }

  handleTpAccounts(accounts) {
    for (let account of accounts)
      account['key'] = account['id']
    this.setState({tp_accounts: accounts});
  }

  requestsTable(active) {
    var requests = active ? this.state.active_requests : this.state.inactive_requests;
    const data = requests === null ? [] : requests.map((request) => {
      const exact_time = request.exact_time ? request.exact_time.toLocaleTimeString() : null;
      const earliest = request.earliest_time ? request.earliest_time.toLocaleTimeString() : null;
      const latest = request.latest_time ? request.latest_time.toLocaleTimeString() : null;
      const preferred = request.preferred_time ? request.preferred_time.toLocaleTimeString() : null;
      const date_str = request.date.toDateString();
      return {
        key: request.id,
        course: request.course,
        date: date_str,
        status: request.status,
        exact_time: exact_time,
        earliest: earliest,
        latest: latest,
        preferred: preferred,
        error: request.error_msg,
        actions: !active ? null : [
          <Button
            icon={<DeleteTwoTone />}
            onClick={() => this.deleteRequest(request.id)} />
        ],
      };
    });

    var columns = [
      { title: 'Course', dataIndex: 'course' },
      { title: 'Status', dataIndex: 'status' },
      { title: 'Date', dataIndex: 'date' },
      { title: 'Exact Time', dataIndex: 'exact_time' },
      { title: 'Earliest', dataIndex: 'earliest' },
      { title: 'Latest', dataIndex: 'latest' },
      { title: 'Preferred', dataIndex: 'preferred' },
      { title: 'Error', dataIndex: 'error' },
    ];

    if (active)
      columns.push({ title: '', dataIndex: 'actions' });

    return <Table size='small'
                  loading={requests === null}
                  columns={columns}
                  dataSource={data} />;
  }

  newCourseButton() {
    return (
      <Button
        style={{ marginRight: 8 }}
        type='primary'
        description='New Course'
        onClick={() => this.setState({showNewCourseForm: true})} >
        New Course
      </Button>
    );
  }

  newAccountButton() {
    return (
      <Button
        style={{ marginRight: 8 }}
        type='primary'
        description='New Provider Account'
        onClick={() => this.setState({showNewAccountForm: true})} >
        New Provider Account
      </Button>
    );
  }

  newRequestButton() {
    return (
      <Button
        style={{ marginRight: 8 }}
        type='primary'
        description='New Request'
        onClick={() => this.setState({showNewRequestForm: true})} >
        New Request
      </Button>
    );
  }

  deleteRequest(id) {
    this.fetchAuthenticated('/request?id=' + id, 'DELETE')
      .then(() => this.fetchActiveRequests())
      .then(() => this.fetchInactiveRequests())
      .catch((error) => alert("Failed to delete request: " + error));
  }

  coursesTable() {
    var data = this.state.courses === null ? [] : this.state.courses.map((course) => {
      const start_time = new Date(course.booking_window_start_time_sec * 1000);
      return {
        key: course.key,
        name: course.name,
        booking_window_days: course.booking_window_days,
        booking_window_start_time: start_time.toLocaleTimeString(),
        time_zone: course.time_zone,
        third_party: course.third_party,
        third_party_info: JSON.stringify(course[course.third_party.toLowerCase()]),
        actions: [
          <Button
            icon={<DeleteTwoTone />}
            onClick={() => this.deleteCourse(course.name)} />
        ],
      };
    });

    const columns = [
      { title: 'Name', dataIndex: 'name' },
      { title: 'Window (days)', dataIndex: 'booking_window_days' },
      { title: 'Window Start Time', dataIndex: 'booking_window_start_time' },
      { title: 'Time Zone', dataIndex: 'time_zone' },
      { title: 'Provider', dataIndex: 'third_party' },
      { title: 'Provider Fields', dataIndex: 'third_party_info' },
      { title: '', dataIndex: 'actions' },
    ];

    return <Table size='small'
                  loading={this.state.courses === null}
                  columns={columns}
                  dataSource={data} />;
  }

  tpAccountsTable() {
    var data = this.state.tp_accounts === null ? [] : this.state.tp_accounts.map((account) => {
      return {
        key: account.key,
        third_party: account.third_party,
        username: account.tp_username,
        actions: [
          <Button
            icon={<DeleteTwoTone />}
            onClick={() => this.deleteTpAccount(account.third_party, account.tp_username)} />
        ],
      };
    });

    const columns = [
      { title: 'Provider', dataIndex: 'third_party' },
      { title: 'Username', dataIndex: 'username' },
      { title: '', dataIndex: 'actions' },
    ];

    return <Table size='small'
                  loading={this.state.tp_accounts === null}
                  columns={columns}
                  dataSource={data} />;
  }

  deleteCourse(name) {
    this.fetchAuthenticated('/course?name=' + name, 'DELETE')
      .then(() => this.fetchCourses())
      .catch((error) => alert("Failed to delete course: " + error));
  }

  addNewCourse(values, form) {
    this.setState({showNewCourseForm: false});

    var third_party_fields = {...values};
    delete third_party_fields.name;
    delete third_party_fields.third_party;
    delete third_party_fields.booking_window_days;
    delete third_party_fields.booking_window_start_time;
    delete third_party_fields.time_zone;

    const start_time = values.booking_window_start_time['$d'].getHours() * 3600 +
                       values.booking_window_start_time['$d'].getMinutes() * 60 +
                       values.booking_window_start_time['$d'].getSeconds();

    var request = {
      name: values.name,
      booking_window_days: values.booking_window_days,
      booking_window_start_time_sec: start_time,
      time_zone: values.time_zone,
      third_party: values.third_party,
    };
    request[values.third_party.toLowerCase()] = third_party_fields;

    this.fetchAuthenticated('/course', 'POST', request)
      .then(() => this.fetchCourses())
      .catch((error) => alert("Failed to add course: " + error));

    form.resetFields();
    this.setState({newCourseFormThirdParty: null});
  }

  cancelNewCourseForm(form) {
    form.resetFields();
    this.setState({newCourseFormThirdParty: null});
    this.setState({showNewCourseForm: false});
  }

  hideDonateModal() {
    this.setState({showDonateModal: false});
  }

  donateModal() {
    return (
      <Modal onCancel={() => this.hideDonateModal()}
             open={this.state.showDonateModal}
             footer={null} >
        <img src={venmo} width={250} />
      </Modal>
    );
  }

  newCourseForm() {
    const form = this.props.form;

    const time_zone_options = [
      { value: 'ET', label: 'ET' },
      { value: 'CT', label: 'CT' },
      { value: 'MT', label: 'MT' },
      { value: 'PT', label: 'PT' },
    ];

    var third_party_form_items = [];
    if (this.state.newCourseFormThirdParty === 'FOREUP') {
      third_party_form_items = [
        <Form.Item label='Course ID'
                   name='course_id'
                   key='course_id'
                   rules={[{ required: true, message: 'Please choose course id' }]} >
          <InputNumber />
        </Form.Item>,
        <Form.Item label='Schedule ID'
                   name='schedule_id'
                   key='schedule_id'
                   rules={[{ required: true, message: 'Please choose schedule id' }]} >
          <InputNumber />
        </Form.Item>,
        <Form.Item label='Booking Class'
                   name='booking_class'
                   key='booking_class'
                   rules={[{ required: true, message: 'Please choose booking class' }]} >
          <InputNumber />
        </Form.Item>,
        <Form.Item label='Teesheet Side ID'
                   name='teesheet_side_id'
                   key='teesheet_side_id'
                   rules={[{ required: true, message: 'Please choose teesheet side id' }]} >
          <InputNumber />
        </Form.Item>,
      ];
    }

    return (
      <Modal title='Add New Course'
             onCancel={() => this.cancelNewCourseForm(form)}
             open={this.state.showNewCourseForm}
             footer={[
               <Button key='cxl_button' onClick={() => this.cancelNewCourseForm(form)} >
                   Cancel
               </Button>,
               <Button key='submit_button' type='primary' form="new_course_form" key="submit" htmlType="submit" >
                   Submit
               </Button>
             ]} >
        <Form id='new_course_form'
              form={form}
              onFinish={(values) => this.addNewCourse(values, form)} >
          <Form.Item label='Name'
                     name='name'
                     rules={[{ required: true, message: 'Please choose course name' }]} >
            <Input />
          </Form.Item>
          <Form.Item label='Booking Window (days)'
                     name='booking_window_days'
                     rules={[{ required: true, message: 'Please choose booking window' }]} >
            <InputNumber />
          </Form.Item>
          <Form.Item label='Booking Window Start Time'
                     name='booking_window_start_time'
                     rules={[{ required: true, message: 'Please choose booking window start time' }]} >
            <TimePicker showNow={false} />
          </Form.Item>
          <Form.Item label='Course Time Zone'
                     name='time_zone'
                     rules={[{ required: true, message: 'Please choose course time zone' }]} >
            <Select options={time_zone_options} />
          </Form.Item>
          <Form.Item label='Booking System'
                     name='third_party'
                     rules={[{ required: true, message: 'Please choose course booking system' }]} >
            <Select onChange={(value) => this.setState({newCourseFormThirdParty: value})}
                    options={third_party_options} />
          </Form.Item>
          {third_party_form_items}
        </Form>
      </Modal>
    );
  }

  newAccountForm() {
    const form = this.props.form;

    return (
      <Modal title='Add New Provider Account'
             onCancel={() => this.cancelNewAccountForm(form)}
             open={this.state.showNewAccountForm}
             footer={[
               <Button key='cxl_button' onClick={() => this.cancelNewAccountForm(form)} >
                   Cancel
               </Button>,
               <Button key='submit_button' type='primary' form="new_account_form" key="submit" htmlType="submit" >
                   Submit
               </Button>
             ]} >
        <Form id='new_account_form'
              form={form}
              onFinish={(values) => this.addNewAccount(values, form)} >
          <Form.Item label='Booking System'
                     name='third_party'
                     rules={[{ required: true, message: 'Please choose booking system' }]} >
            <Select options={third_party_options} />
          </Form.Item>
          <Form.Item label='Username'
                     name='tp_username'
                     rules={[{ required: true, message: 'Please enter username' }]} >
            <Input />
          </Form.Item>
          <Form.Item label='Password'
                     name='tp_password'
                     rules={[{ required: true, message: 'Please enter password' }]} >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  deleteTpAccount(third_party, username) {
    this.fetchAuthenticated('/tp_account?third_party=' + third_party + '&tp_username=' + username,
                            'DELETE')
      .then(() => this.fetchTpAccounts())
      .catch((error) => alert("Failed to delete account: " + error));
  }

  addNewAccount(values, form) {
    this.setState({showNewAccountForm: false});

    this.fetchAuthenticated('/tp_account', 'POST', values)
      .then(() => this.fetchTpAccounts())
      .catch((error) => alert("Failed to add account: " + error));

    form.resetFields();
  }

  cancelNewAccountForm(form) {
    form.resetFields();
    this.setState({showNewAccountForm: false});
  }

  newRequestForm() {
    const form = this.props.form;

    var course_options = [];
    var tp_user_options = [];
    if (this.state.courses) {
      for (let course of this.state.courses) {
        course_options.push(
          {
            value: course.name,
            label: course.name,
          }
        );
      }
      for (let course of this.state.courses) {
        if (course.name == this.state.newRequestFormCourse) {
          for (let account of this.state.tp_accounts) {
            if (account.third_party === course.third_party) {
              tp_user_options.push({
                value: account.tp_username,
                label: account.tp_username + " (" + account.third_party + ")",
              })
            }
          }
          break;
        }
      }
    }

    const booking_strategy_options = [
      {
        value: 'EXACT',
        label: 'EXACT',
      },
      {
        value: 'RANGE',
        label: 'RANGE',
      },
    ];

    var booking_strategy_form_items = [];
    if (this.state.newRequestFormStrategy === 'EXACT') {
      booking_strategy_form_items = [
        <Form.Item label='Exact Tee Time'
                   name='exact_time'
                   key='exact_time'
                   rules={[{ required: true, message: 'Please choose exact tee time' }]} >
          <TimePicker showNow={false} />
        </Form.Item>
      ];
    } else if (this.state.newRequestFormStrategy === 'RANGE') {
      booking_strategy_form_items = [
        <Form.Item label='Earliest Tee Time'
                   name='earliest_time'
                   key='earliest_time'
                   rules={[{ required: true, message: 'Please choose earliest tee time' }]} >
          <TimePicker showNow={false} />
        </Form.Item>,
        <Form.Item label='Latest Tee Time'
                   name='latest_time'
                   key='latest_time'
                   rules={[{ required: true, message: 'Please choose latest tee time' }]} >
          <TimePicker showNow={false} />
        </Form.Item>,
        <Form.Item label='Preferred Tee Time'
                   name='preferred_time'
                   key='preferred_time'
                   rules={[{ required: true, message: 'Please choose preferred tee time' }]} >
          <TimePicker showNow={false} />
        </Form.Item>,
      ];
    }

    return (
      <Modal title='Add New Booking Request'
             onCancel={() => this.cancelNewRequestForm(form)}
             open={this.state.showNewRequestForm}
             footer={[
               <Button key='cxl_button' onClick={() => this.cancelNewRequestForm(form)} >
                   Cancel
               </Button>,
               <Button key='submit_button' type='primary' form="new_request_form" key="submit" htmlType="submit" >
                   Submit
               </Button>
             ]} >
        <Form id='new_request_form'
              form={form}
              onFinish={(values) => this.addNewRequest(values, form)} >
          <Form.Item label='Course'
                     name='course'
                     rules={[{ required: true, message: 'Please choose course' }]} >
            <Select options={course_options}
                    onChange={(value) => this.setState({newRequestFormCourse: value})} />
          </Form.Item>
          <Form.Item label='Date'
                     name='date'
                     rules={[{ required: true, message: 'Please choose date' }]} >
            <DatePicker showToday={false} />
          </Form.Item>
          <Form.Item label='Provider Account'
                     name='tp_username'
                     rules={[{ required: true, message: 'Please choose provider account' }]} >
            <Select options={tp_user_options} />
          </Form.Item>
          <Form.Item label='Booking Strategy'
                     name='booking_strategy'
                     rules={[{ required: true, message: 'Please choose booking strategy' }]} >
            <Select options={booking_strategy_options}
                    onChange={(value) => this.setState({newRequestFormStrategy: value})} />
          </Form.Item>
          {booking_strategy_form_items}
        </Form>
      </Modal>
    );
  }

  addNewRequest(values, form) {
    this.setState({showNewRequestForm: false});

    var strategy_fields = {...values};
    delete strategy_fields.course;
    delete strategy_fields.tp_username;
    delete strategy_fields.booking_strategy;

    values.date = values.date['$d'];
    values.date.setHours(0);
    values.date.setMinutes(0);
    values.date.setSeconds(0);
    values.date.setMilliseconds(0);

    var request = {
      course: values.course,
      tp_username: values.tp_username,
      date_sec: Math.round(values.date.getTime() / 1000)
    };

    const strategy = values.booking_strategy.toLowerCase() + '_booking_strategy'
    if (values.booking_strategy === 'EXACT') {
      const exact_time = values.exact_time['$d'].getHours() * 3600 +
                         values.exact_time['$d'].getMinutes() * 60 +
                         values.exact_time['$d'].getSeconds();
      request[strategy] = {exact_time_sec: exact_time};
    } else {
      const earliest_time = values.earliest_time['$m'].getHours() * 3600 +
                         values.earliest_time['$d'].getMinutes() * 60 +
                         values.earliest_time['$d'].getSeconds();
      const latest_time = values.latest_time['$d'].getHours() * 3600 +
                         values.latest_time['$d'].getMinutes() * 60 +
                         values.latest_time['$d'].getSeconds();
      const preferred_time = values.preferred_time['$d'].getHours() * 3600 +
                         values.preferred_time['$d'].getMinutes() * 60 +
                         values.preferred_time['$d'].getSeconds();
      request[strategy] = {earliest_time_sec: earliest_time,
                           latest_time_sec: latest_time,
                           preferred_time_sec: preferred_time};
    }

    this.fetchAuthenticated('/request', 'POST', request)
      .then(() => this.fetchActiveRequests())
      .catch((error) => alert("Failed to add request: " + error));

    form.resetFields();
    this.setState({newRequestFormCourse: null});
    this.setState({newRequestFormStrategy: null});
    this.setState({showNewRequestForm: false});
  }

  cancelNewRequestForm(form) {
    form.resetFields();
    this.setState({newRequestFormCourse: null});
    this.setState({newRequestFormStrategy: null});
    this.setState({showNewRequestForm: false});
  }

  render() {
    const sign_out_cb = this.props.sign_out_cb;

    const tab_items = [
      {
        key: 'active',
        label: 'Active Requests',
        children: this.requestsTable(true),
      },
      {
        key: 'closed',
        label: 'Closed Requests',
        children: this.requestsTable(false),
      },
      {
        key: 'courses',
        label: 'Courses',
        children: this.coursesTable(),
      },
      {
        key: 'third_party_accounts',
        label: 'Provider Accounts',
        children: this.tpAccountsTable(),
      },
    ];

    return (
      <>
        <Row style={{ marginLeft: 8, marginRight: 8, marginTop: 8, marginBottom: 8 }}>
          <Card>
            {this.newCourseButton()}
            {this.newAccountButton()}
            {this.newRequestButton()}
            <Button
              style={{ marginRight: 8 }}
              type='primary'
              onClick={() => this.setState({showDonateModal: true})} >
              Donate
            </Button>
            <Button
              style={{ marginRight: 8 }}
              type='primary'
              onClick={sign_out_cb} >
              Sign Out
            </Button>
          </Card>
        </Row>
        <Row style={{ marginLeft: 8, marginRight: 8, marginTop: 8, marginBottom: 8 }}>
          <Card>
            <Tabs defaultActiveKey='active' items={tab_items} />
          </Card>
        </Row>
        {this.newCourseForm()}
        {this.newAccountForm()}
        {this.newRequestForm()}
        {this.donateModal()}
      </>
    );
  }
}

export { UserLanding };
