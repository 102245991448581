import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { UserLanding } from './UserLanding';
import { Form } from 'antd';

function App() {
  const [form] = Form.useForm();
  return (
      <Authenticator>
          {({ signOut, user }) => (
              <div>
                  <UserLanding user={user}
                               sign_out_cb={signOut}
                               form={form} />
              </div>
          )}
      </Authenticator>
  );
}

export default App;
